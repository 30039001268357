import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Projects from './pages/Projects';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
//import Cards from './pages/Cards';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/projects",
    element: <Projects/>,
  },
//  {
//    path: "cards",
//    element: <Cards/>
//  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
