import * as React from 'react'
import '../ProjectCard.css'

const ProjectCard = ({  logo, title, content, link  }) => {
  return (
    <div className='project-card'>
        <div className='project-logo'>
            <img alt="" src={logo}/>
        </div>
        <h2>{title}</h2>
        <p>{content}</p>
        <a href={link}>View Project</a>
    </div>
  );
};
export default ProjectCard