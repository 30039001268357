import React from 'react'
import Navbar from '../components/NavBar'
import ProjectCard from '../components/ProjectCard'

function Projects() {
  return (
    <div>
        <Navbar/>
        <h1 style={{  textAlign: 'center'  }}>Projects</h1>
        <div className="container" style={{  padding: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap',alignContent: 'center', alignItems: 'center', justifyContent: 'center'  }}>
            <ProjectCard
                title="Rhythm Punk"
                content="Coming Soon™"
                link="https://taynet.uk"
                logo="https://w7.pngwing.com/pngs/171/721/png-transparent-wip-text-lettering-thumbnail.png"
            />
            <ProjectCard
                title="Project 2"
                content="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                link="https://taynet.uk"
                logo="project2.jpg"
            />
            <ProjectCard
                title="Project 3"
                content="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                link="https://taynet.uk"
                logo="project3.jpg"
            />
    
        </div>
    </div>
  )
}

export default Projects
